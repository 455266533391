import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"300px","padding":"15px"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.content.text))}}),_c('div',{staticClass:"canvas-socials",class:{
      'canvas-socials_tile': _vm.content.style === 'tile',
    },style:("align-items:center;padding:0;")},_vm._l((_vm.socials),function(contact){return _c('div',{key:contact.id,style:(("margin-right:" + (_vm.content.style === 'tile' ? '15px' : '0') + ";width:" + (_vm.content.style === 'tile' ? ((+_vm.landing.branding.topMenu.styles.size * 2.5) + "px") : _vm.content.width + '%')))},[_c('a',{directives:[{name:"external-link",rawName:"v-external-link"}],staticClass:"canvas-socials__item",style:(("background-color: " + (contact.color) + ";\n                                      border-radius: " + (_vm.content
                                          .radius) + "px;")),attrs:{"href":_vm.getLink(contact.text, contact.link)}},[(contact.icon)?_c('span',{staticClass:"canvas-socials__icon",class:{ canvas__fill: _vm.fillButton(contact.text) },domProps:{"innerHTML":_vm._s(contact.icon)}}):_vm._e(),_vm._v(" "+_vm._s(contact.text)+" ")])])}),0),_c('div',{staticStyle:{"min-height":"auto"},domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.content.second_text))}}),(_vm.content.map.enableButton)?_c('div',{staticClass:"canvas-socials",staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('a',{directives:[{name:"external-link",rawName:"v-external-link"}],staticClass:"canvas-socials__item",style:(("color:#000 !important;background-color:#eeeeee;border-radius: " + (_vm.content.radius) + "px;")),attrs:{"href":("http://maps.google.com/maps?q=" + (_vm.content.map.lat_btn) + "," + (_vm.content.map.lng_btn)),"target":"_blank"}},[_c('span',{staticClass:"canvas-socials__icon"},[_c(VIcon,[_vm._v("mdi-google-maps")])],1),_vm._v(" Directions with google maps ")])]):_vm._e(),(_vm.content.map.show)?_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"300px"},attrs:{"zoom":_vm.content.map.zoom,"center":{ lat: +_vm.content.map.lat, lng: +_vm.content.map.lng },"options":{
      streetViewControl: _vm.content.map.streetViewControl,
      mapTypeControl: _vm.content.map.mapTypeControl,
      zoomControl: _vm.content.map.zoomControl,
      fullscreenControl: _vm.content.map.fullscreenControl,
    },"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
        lat: +_vm.content.map.lat,
        lng: +_vm.content.map.lng,
      },"clickable":true}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }