<template>
  <div
      style="min-height: 300px;padding:15px;">
    <div
      v-html="htmlData(content.text)"
    />
    <div
      class="canvas-socials"
      :style="`align-items:center;padding:0;`"
      :class="{
        'canvas-socials_tile': content.style === 'tile',
      }"
    >
      <div
        v-for="contact in socials"
        :key="contact.id"
        :style="
          `margin-right:${content.style === 'tile' ? '15px' : '0'};width:${content.style === 'tile' ? `${+landing.branding.topMenu.styles.size * 2.5}px` : content.width + '%'}`
        "
      >
        <a
          class="canvas-socials__item"
          :href="getLink(contact.text, contact.link)"
          v-external-link
          :style="
            `background-color: ${contact.color};
                                        border-radius: ${
                                          content
                                            .radius
                                        }px;`
          "
        >
          <span
            v-if="contact.icon"
            class="canvas-socials__icon"
            :class="{ canvas__fill: fillButton(contact.text) }"
            v-html="contact.icon"
          ></span>
          {{ contact.text }}
        </a>
      </div>
    </div>
    <div
      
      style="min-height: auto"
      v-html="htmlData(content.second_text)"
    />
    <div
      class="canvas-socials"
      v-if="content.map.enableButton"
      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
    >
      <a
        class="canvas-socials__item"
        :href="
          `http://maps.google.com/maps?q=${content.map.lat_btn},${content.map.lng_btn}`
        "
        target="_blank"
        v-external-link
        :style="
          `color:#000 !important;background-color:#eeeeee;border-radius: ${content.radius}px;`
        "
      >
        <span class="canvas-socials__icon"
          ><v-icon>mdi-google-maps</v-icon></span
        >
        Directions with google maps
      </a>
    </div>
    <GmapMap
      ref="gmap"
      v-if="content.map.show"
      :zoom="content.map.zoom"
      :center="{ lat: +content.map.lat, lng: +content.map.lng }"
      :options="{
        streetViewControl: content.map.streetViewControl,
        mapTypeControl: content.map.mapTypeControl,
        zoomControl: content.map.zoomControl,
        fullscreenControl: content.map.fullscreenControl,
      }"
      map-type-id="terrain"
      style="width: 100%; height: 300px"
    >
      <GmapMarker
        :position="{
          lat: +content.map.lat,
          lng: +content.map.lng,
        }"
        :clickable="true"
      />
    </GmapMap>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "typeContactPreview",
  props: ["content"],
  data() {
    return {
      datenow: "",
    };
  },
  computed: {
    ...mapState(["landing"]),
    socials(){
        return this.content.content.filter(item => item.active);
    }
  },
  methods: {
    getLink(text, link) {
      let linkcheck = "";
      if (link) {
        linkcheck = link;
      }
      if (text === "Whatsapp") {
        return "whatsapp://" + linkcheck;
      } else if (text === "Viber") {
        return "viber://" + linkcheck;
      } else if (text === "Email") {
        return "mailto:" + linkcheck;
      } else if (text === "Telephone") {
        return "tel:" + linkcheck;
      } else {
        return linkcheck;
      }
    },
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    htmlData(data, type) {
      if(data){

      const weatherInd = data.split(" ").indexOf(":weather:");
      let result = data.split(" ");
      if (weatherInd && this.landing.advanced.global.show_weather) {
        const newArr = result;
        newArr[weatherInd] =
          this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
            0
          ) + " ℃";
        result = newArr;
      }
      const timeInd = data.split(" ").indexOf(":time:");
      if (timeInd) {
        const newArr = result;
        newArr[timeInd] = this.datenow;
        result = newArr;
      }
      const dateInd = data.split(" ").indexOf(":date:");
      if (dateInd) {
        const newArr = result;
        newArr[dateInd] = this.dateToday;
        result = newArr;
      }
      const user_name = data.split(" ").indexOf(":name:");
      if (
        user_name &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_name] = "Agent";
        result = newArr;
      }
      const user_surname = data.split(" ").indexOf(":surname:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_surname] = "Smith";
        result = newArr;
      }
      const user_birthday = data.split(" ").indexOf(":birthday:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_birthday] = "24.03.1999";
        result = newArr;
      }
      const user_room = data.split(" ").indexOf(":room:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_room] = "707";
        result = newArr;
      }

      const user_auth = data.split(" ").indexOf(":auth:");
      if (user_auth && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_auth] = `<a href="#">Login</a>`;
        result = newArr;
      }
      const user_logout = data.split(" ").indexOf(":logout:");
      if (user_logout && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_logout] = `<a href="#">Logout</a>`;
        result = newArr;
      }
      return result.join(" ");
      }
    },
    fillButton(text) {
      if (text === "Email" || text === "Telephone" || text === "Custom") {
        return true;
      }
    },
  },
  mounted() {
    this.timeNow();
  },
};
</script>

<style lang="scss">
.gm-svpc img{
  min-width: 30px;
  min-height: 30px;
}</style>
